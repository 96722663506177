import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import cn from 'classnames';

const leasedType = [
  { value: 'false', name: 'new_search.buy', id: 'new-search-form-buy' },
  { value: 'true', name: 'new_search.rent', id: 'new-search-form-rent' }
];

const NewSearchLeased = ({
  t,
  value,
  margin,
  defaultValue,
  submitOnChange = false
}) => {
  const { submit, change } = useForm();

  const labelClasses = isActive =>
    cn('filters__leased_type', {
      'filters__leased_type-active': isActive
    });

  const submitOrNot = leasedValue => {
    if (leasedValue === 'true') {
      change('paid_daily', ['false']);
    }
    if (submitOnChange) {
      change('leased', leasedValue);
      submit();
    }
  };

  return (
    <div className="filters__options-wrapper">
      {leasedType.map(type => (
        <div
          tabIndex={0}
          key={type.id}
          role="button"
          data-stat={type.id}
          className="filters__option"
          onClick={() => submitOrNot(type.value)}
          onKeyPress={() => submitOrNot(type.value)}
        >
          <label
            id={type.id}
            style={{ margin }}
            className={labelClasses(value === type.value)}
          >
            <Field
              type="radio"
              name="leased"
              component="input"
              value={type.value}
              className="display-none"
              defaultValue={defaultValue}
            />
            {t(type.name)}
          </label>
        </div>
      ))}
    </div>
  );
};

NewSearchLeased.propTypes = {
  t: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  defaultValue: PropTypes.string,
  submitOnChange: PropTypes.bool
};

export default NewSearchLeased;
