import * as React from 'react';
import PropTypes from 'prop-types';
import { palette } from '../styles/_palette';

const IconPropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

export const AngleDownIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 13}
    height={props.height || 8}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || palette.white}
      d="M1.524.5.5 1.52l6 5.98 6-5.98L11.476.5 6.5 5.46 1.524.5Z"
    />
  </svg>
);
AngleDownIcon.propTypes = IconPropTypes;

export const CloseIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 11}
    height={props.height || 10}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || palette.white}
      d="M10.5.87 9.63 0 5.5 4.13 1.37 0 .5.87 4.63 5 .5 9.13l.87.87L5.5 5.87 9.63 10l.87-.87L6.37 5 10.5.87Z"
    />
  </svg>
);
CloseIcon.propTypes = IconPropTypes;
