import { useEffect } from 'react';

export const useLockBodyScroll = isOpen => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    document.body.style.position = isOpen ? 'fixed' : 'unset';
    document.body.style.height = isOpen ? '100dvh' : 'unset';
    document.body.style.width = isOpen ? '100vw' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.height = 'unset';
      document.body.style.width = 'unset';
    };
  }, [isOpen]);
};
